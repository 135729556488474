.cc-banner{
  position: relative!important;
}

.cta-button, .button, button, .readMore, .addToCalender{
  &:hover{
    background-color: $blue-darker;
    border-color: $blue-darker;
    transform: scale(1.025);
  }
}

@media (min-width: map-get($map: $breakpoints, $key: xl)) {

  .headerContent{
    height: 100px;
  }
  .mainLogo{
    box-shadow: 0 5px 15px -5px rgba(0,0,0,.2);
  }

}

.mainCallToAction {
  .socialIcons{ // remove from snippet instead if approved by client
    display: none;
  }
}

@media (min-width: map-get($map: $breakpoints, $key: lg) + 1px ) {

  // ensure admin menu is in correct place, this does misalign when logged in but it is only featuring basket on shop page so not a big issue
  .menuAdminContainer{
    margin:auto;
    position: relative;
  }
  #menuAdmin{
    position: absolute;
    right: 530px;
    top: 13px;
    z-index: 2;
    width: auto;
  }

}

.headerContent .header-search .searchContainer input{
  font-family: $headings-font-family;
}

.mainCallToAction {
  .cta-button{
    font-size: $font-size-medium!important;
  }
  .cta-button.shop{
    background-color: $blue-dark;
    border-color: $blue-dark;
    color: white;
    &:hover{
      background-color: $blue-dark;
      border-color: $blue-dark;
    }
  }
  .cta-button.contact{
    &:hover{
      background-color: $blue-darker;
      border-color: $blue-darker;
    }
  }
}

.cta-button.donate, 
.button.donate, 
button.donate {
  color: white;
  &:hover{
    background-color: $orange;
    border-color: $orange;
    color: white;
  }
}


.homeIntro h2 {
  @include heading-underline(
    $justify: center,
    $colour: $blue-light,
    $height: 3px,
    $margin-top: 10px,
    $width: 4em
  );
}

.carousel{
  padding: 0;
}

.carouselSlideReadMore {
  margin-top: 1.5rem;
  &:hover {
    background-color: white;
    color: $blue-darker;
  }
}

// .carouselDetailWrapper{
//   position: relative;
//   &:after{
//     content:'';
//     position: absolute;
//     right:-60px;
//     top: 0;
//     height: 100%;
//     width: 60px;
//     background-image: url($assets-path + 'curve-darker-blue.svg');
//     background-position: right;
//   }
// }

.homeImpactWrapper h2,
h3.feedsTitle{
  @include heading-underline(
    $justify: center,
    $colour: $blue-light,
    $height: 3px,
    $margin-top: 10px,
    $width: 2em
    );
}

.homeFeaturesReadMore{
  text-align: right;
  background: white;
  color: $blue-darker;
  display: block;
  width: 150px;
  text-align: center;
  margin: 15px 0 0;
  &:hover{
    background: white;
    color: $blue-darker;
    transform: none;
  }
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn{
  border-color: $orange; // variable doesn't appear to be working
  border-radius: 50px 0 50px 50px;
}

.feedItem{
  .home-feed-image-link{
    border-radius: 30px;
  }
  span.readMore{
    display: block;
    width: 150px;
    margin: 20px auto;
  }
}

.footerBox{
  padding-right:40px;

  button#groupSignUpBtn {
    border: 2px solid white;
    &:hover{
      background-color: white;
      color: $blue-darker;
    }
  } 

  &.footerBox.Fixedpositionbottomcornerbutton{
    position: fixed;
    display: block;
    background-color: $orange;
    z-index: 2;
    bottom: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 0;
    @media (min-width: map-get($map: $breakpoints, $key: md)) {
      width: 250px;
      height: 250px;
      max-width: 250px;
      bottom: -110px;
      right: -75px;
      border-radius: 120px;
      transition: all .3s ease-in-out;
      padding: 15px 45px 20px 20px;
      font-size: 15px!important;
      &:hover{
        transform: scale(1.03);
      }
    }
    h5 {
      font-size: 1.5em!important;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
      @media (min-width: map-get($map: $breakpoints, $key: md)) {
        text-align: right;
        transition: all .3s ease-in-out;
        max-width: 150px;
      }
      a{
        display: block;
        text-decoration: none;
        padding: 20px;
        &:hover {
        color: white;        
        text-decoration: none;
        }
      }
    }
    



  }

  &.Footer-Legal{
    text-align: center;
    margin-top:40px;
    font-size: $font-size-small;
  }

}

// Listings

// Event listing

body.eventsList{
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) footer {
    strong {
      display: inline-block;
    }
    p:before{
      content: ''!important;
      display: unset;
      width: unset;
      margin: unset;
    }
    .associatedStartDate:before{
      content: '';
    }
    .associatedEndDate{
      display: block!important;
    }
  }
}

// In memory

// Lazy load fix
.inMemoryListingPage .listedPost.listedInMemory:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) .banner {
  min-height: 0.1px;
}
// Add margin between listed posts
.inMemoryListingPage #memorialArea {
  column-gap: 25px;
}

// Posts

h1.title{
  @include heading-underline(
    $width: 100%,
    $colour: $blue-light,
    $height: 4px,
    $margin-top: .2em,
    $margin-bottom: 1em
  );
}

.associated{
  margin-bottom: 3em;
}

.associatedBox .readMore{
  display: table;
  margin: 1em 0;
  &:hover a{
    text-decoration: none;
  }
}

blockquote {
  padding: 20px;
  margin: 2em 0;
  border-left: 5px solid #0099d9;
}
blockquote p{
  display: inline;
}
blockquote:before {
  content: '\f10d';
  display: inline-block!important; 
  margin-right: 5px;
  font-family: "FontAwesome";
  text-align: center;
}
blockquote:after {
  content: '\f10e';
  display: inline-block!important; 
  margin-left: 5px;
  font-family: "FontAwesome";
  text-align: center;
}
blockquote.grey {
  background-color: #f6f6f6;
}

// Bespoke css copied over from old site

/* AW 02.02.2017 Hide contact us address ZD 15334 */

.contactOfficePost {
  display: none;
}

/* AW 02.08.2017 Hide intranet text when logged out ZD 19835 n.b. this doesn't appear to have any affect */

body.pages-Category-intranet .listingIntro p:nth-child(2) {
  display: none;
}

body.pages-Category-intranet.userLoggedIn .listingIntro p:nth-child(2) {
  display: inline;
}

/* AW 06.10.2017 Hide parent appeal ZD 21847 */

.parentAppeal {
  display: none;
}

/* AW 12.10.2017 Remove share options ZD 22155 */

.PostSystemCategory_remove-share-options .shareWrapper {
  display: none;
}

/* AW 06.04.2018 ZD 27889 */

.contactForm{
  .formQuestion.website,
  .formQuestion.organisationName,
  .formQuestion.jobTitle,
  .contactAdditionalInfo legend {
    display: none;
  }
}

/* DES-4644 */
.inMemoryCreateAssociatedAppeal {
  display: none;
}

@include appeal-field("light-up-christmas", "christmas");
@include appeal-field("meadow-of-remembrance", "flowers");

body.appeal-meadow-of-remembrance,
body.appeal-light-up-christmas {
  .commentsList .commentContentWrapper {
    background-color: $brand-primary;
    color: white;
    &:before{
      color: $brand-primary;
    }
  }
}

body.appeal-meadow-of-remembrance {
  .headerText .title {
    color: $brand-primary;
  }
}


// Fundraiser pic IM-811

.postContent.fundraiserContent .fundraiserProfilePic {
  max-width: 100%;
}

.blogsPostBody .post .contentBlock .postAside.postFeedWrapper, .eventsPostBody .post .contentBlock .postAside.eventWidgetsWrapper + .postAside.postFeedWrapper, .appealsPostBody .post .contentBlock aside.postAside .findAFundraiserWidget {
  display: none;
}